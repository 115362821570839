import { FETCH_RECIPES, SORT_RECIPES, EMPTY_RECIPES } from 'actions/types';

var NUM_INGREDIENTS = "ingredients_in_recipe_num"

function arraysEqual(_arr1, _arr2) {
  if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
    return false;

  var arr1 = _arr1.concat().sort();
  var arr2 = _arr2.concat().sort();

  for (var i = 0; i < arr1.length; i++) {

    if (arr1[i] !== arr2[i])
      return false;
  }
  return true;
}

function dynamicSort(property, order) {
  var sortOrder = 0;
  if(order === "DESC")
  {
    sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
  }
  else if(order === "ASC")
  {
    sortOrder = -1;
    if(property[0] === "-") {
        sortOrder = 1;
        property = property.substr(1);
    }
  }

  return function (a,b) {
      var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

function dynamicSortMultiple(order) {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0, result = 0, numberOfProperties = props.length;
    /* try getting a different result from 0 (equal)
      * as long as we have extra properties to compare
      */
    while(result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i], order)(obj1, obj2);
      i++;
    }
    return result;
  }
}

function sortRecipes(ingredientFirst, recipes, value, order){
  return recipes.sort(ingredientFirst ? dynamicSortMultiple(order, NUM_INGREDIENTS, value) : 
                                        dynamicSort(value, order));
}

export default function(state = { 'recipes': [], 'sort': 'Score' }, action) {
  switch (action.type) {
    case FETCH_RECIPES:
    {
      let recipes = action.payload.data;
      
      if (recipes === "No data"){
        return { ...state, 'recipes': 'No data' };
      }
      
      if(arraysEqual(state['recipes'], recipes))
        return { ...state };
      else{
        recipes = sortRecipes(true, recipes, "score")
        return { ...state, 'recipes': recipes }
      }
    }

    case EMPTY_RECIPES:
      return {...state, 'recipes': []}

    case SORT_RECIPES:
      let recipes = state['recipes']
      if(recipes !== 'No data'){
        let data = action.payload
        switch(data.option){
          case "Score":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "score", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "NRF RACC - Reference Amount Customarily Consumed":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "nrf", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "NRF 100 Calories":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "nrf2", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "Calories":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "calories", "ASC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "Macronutrient Balance":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "macronutrient_score", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "Cumulative Score by Weighted Average Method":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "cumulative_score_1", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          case "Cumulative Score by Cogent Confabulation Method":
            recipes = sortRecipes(data.sort_by_ingredients_first, recipes, "cumulative_score_2", "DESC")
            return { ...state, 'recipes': recipes, 'sort': data.option };
          default:
            return { ...state };
        }
      }else return { ...state }; 
      
    default:
      return state;
  }
}