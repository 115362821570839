import React, { Component } from 'react';
import goodNutrients from '../images/thumbnail_good_nutrients.png';

class GoodNutrientsGraph extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 1273,
            height: 1280,
            protein : 0,
            dietaryFibre : 0, 
            vitaminA : 0,
            vitaminC : 0,
            thiamine : 0, 
            niacin : 0,
            vitaminB6 : 0,
            calcium : 0, 
            magnesium : 0,
            iron : 0,
            potassium : 0, 
            folate : 0
        };
    }

    componentWillMount() {
        const width = this.props.width;
        const height = this.props.height;
        const protein = this.props.protein;
        const dietaryFibre = this.props.dietaryFibre;
        const vitaminA = this.props.vitaminA;
        const vitaminC = this.props.vitaminC;
        const thiamine = this.props.thiamine;
        const niacin = this.props.niacin;
        const vitaminB6 = this.props.vitaminB6;
        const calcium = this.props.calcium;
        const magnesium = this.props.magnesium;
        const iron = this.props.iron;
        const potassium = this.props.potassium;
        const folate = this.props.folate;

        if(width)
        {
            this.setState({ width : width });
        }

        if(height)
        {
            this.setState({ height : height });
        }

        if(protein)
        {
            this.setState({ protein : protein });
        }

        if(dietaryFibre)
        {
            this.setState({ dietaryFibre : dietaryFibre });
        }

        if(vitaminA)
        {
            this.setState({ vitaminA : vitaminA });
        }

        if(vitaminC)
        {
            this.setState({ vitaminC : vitaminC });
        }

        if(thiamine)
        {
            this.setState({ thiamine : thiamine });
        }

        if(niacin)
        {
            this.setState({ niacin : niacin });
        }

        if(vitaminB6)
        {
            this.setState({ vitaminB6 : vitaminB6 });
        }

        if(calcium)
        {
            this.setState({ calcium : calcium });
        }

        if(magnesium)
        {
            this.setState({ magnesium : magnesium });
        }

        if(iron)
        {
            this.setState({ iron : iron });
        }

        if(potassium)
        {
            this.setState({ potassium : potassium });
        }

        if(folate)
        {
            this.setState({ folate : folate });
        }
    }

    componentDidMount() {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = this.refs.image;
        const lineWidth = canvas.height / 150;
        const circleDiameter = canvas.height / 112.5;

        img.onload = () => {

            ctx.drawImage(img, 0, 0, img.width, img.height,         // source rectangle
                            0, 0, canvas.width, canvas.height);     // destination rectangle

            //Protein
            let startX = canvas.width / 2 - canvas.width / 212.17;
            let startY = canvas.height / 2 - canvas.height / 77.58;
            let angle = 4.708;
            let halfValue = canvas.width / 4.21;
            let fullValue = canvas.width / 2.44;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.protein, lineWidth, circleDiameter);
            
            //Dietary Fibre
            startX = canvas.width / 2 + canvas.width / 848.67;
            startY = canvas.height / 2 - canvas.height / 85.33;
            halfValue = canvas.width / 4.16;
            fullValue = canvas.width / 2.46;
            angle = 5.185;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.dietaryFibre, lineWidth, circleDiameter);

            //Vitamin A
            startX = canvas.width / 2 + canvas.width / 115.73;
            startY = canvas.height / 2 - canvas.height / 160;
            halfValue = canvas.width / 4.06;
            fullValue = canvas.width / 2.47;
            angle = 5.73;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.vitaminA, lineWidth, circleDiameter);

            //Vitamin C
            startX = canvas.width / 2 + canvas.width / 97.92;
            startY = canvas.height / 2 - canvas.height / 1280;
            halfValue = canvas.width / 4;
            fullValue = canvas.width / 2.46;
            angle = 6.275;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.vitaminC, lineWidth, circleDiameter);

            //Thiamine (B1)
            startX = canvas.width / 2 + canvas.width / 106.08;
            startY = canvas.height / 2 + canvas.height / 160;
            halfValue = canvas.width / 4.12;
            fullValue = canvas.width / 2.45;
            angle = 6.89;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.thiamine, lineWidth, circleDiameter);
            
            //Niacin (B3)
            startX = canvas.width / 2 + canvas.width / 254.6;
            startY = canvas.height / 2 + canvas.height / 85.33;
            halfValue = canvas.width / 4.23;
            fullValue = canvas.width / 2.45;
            angle = 7.315;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.niacin, lineWidth, circleDiameter);
            
            //Vitamin B6
            startX = canvas.width / 2 - canvas.width / 318.25;
            startY = canvas.height / 2 + canvas.height / 71.11;
            halfValue = canvas.width / 4.32;
            fullValue = canvas.width / 2.45;
            angle = 7.855;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.vitaminB6, lineWidth, circleDiameter);

            //Calcium
            startX = canvas.width / 2 - canvas.width / 90.93;
            startY = canvas.height / 2 + canvas.height / 85.33;
            halfValue = canvas.width / 4.23;
            fullValue = canvas.width / 2.44;
            angle = 8.37;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.calcium, lineWidth, circleDiameter);

            //Magnesium
            startX = canvas.width / 2 - canvas.width / 63.65;
            startY = canvas.height / 2 + canvas.height / 182.86;
            halfValue = canvas.width / 4.06;
            fullValue = canvas.width / 2.44;
            angle = 8.83;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.magnesium, lineWidth, circleDiameter);

            //Iron
            startX = canvas.width / 2 - canvas.width / 60.62;
            startY = canvas.height / 2 - canvas.height / 1280;
            halfValue = canvas.width / 3.93;
            fullValue = canvas.width / 2.44;
            angle = 9.425;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.iron, lineWidth, circleDiameter);

            //Potassium
            startX = canvas.width / 2 - canvas.width / 70.72;
            startY = canvas.height / 2 - canvas.height / 160;
            halfValue = canvas.width / 3.98;
            fullValue = canvas.width / 2.44;
            angle = 9.945;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.potassium, lineWidth, circleDiameter);

            //Folate
            startX = canvas.width / 2 - canvas.width / 97.92;
            startY = canvas.height / 2 - canvas.height / 91.43;
            halfValue = canvas.width / 4.12;
            fullValue = canvas.width / 2.46;
            angle = 10.51;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.folate, lineWidth, circleDiameter);
        }
    }

    drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, nutrientPercetange, lineWidth, circleDiameter) {
        const lineColor = "#fa41fe";

        let valueX = startX;        //X coordinate to draw
        let valueY = startY;        //Y coordinate to draw

        if(nutrientPercetange <= 0)
        {
            valueX = startX;
            valueY = startY;
        }
        else if(nutrientPercetange > 0 && nutrientPercetange <= 50)
        {
            valueX = startX + (nutrientPercetange / 50 * halfValue * Math.cos(angle));
            valueY = startY + (nutrientPercetange / 50 * halfValue * Math.sin(angle));
        }
        else if(nutrientPercetange > 50 && nutrientPercetange <= 100)
        {
            valueX = startX + ((halfValue + (nutrientPercetange - 50) / 50 * (fullValue - halfValue)) * Math.cos(angle));
            valueY = startY + ((halfValue + (nutrientPercetange - 50) / 50 * (fullValue - halfValue)) * Math.sin(angle));
        }
        else if(nutrientPercetange > 100)
        {
            valueX = startX + fullValue * Math.cos(angle);
            valueY = startY + fullValue * Math.sin(angle);
        }
        
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor;
        ctx.moveTo(startX, startY);
        ctx.lineTo(valueX, valueY);
        ctx.stroke();       // Draw it
        ctx.beginPath();
        ctx.fillStyle = lineColor;
        ctx.arc(valueX, valueY, circleDiameter, 0, 2 * Math.PI);
        ctx.fill();
    }

    render() {
        const width = this.state.width;
        const height = this.state.height;
        return(
            <div>
                <canvas ref="canvas" width={width} height={height} resize="true" style={{'width' : '100%', 'height' : '100%', 'minWidth' : '250px', 'padding' : '1em'}}/>
                <img ref="image" alt="good_nutrients" src={goodNutrients} className="hidden" />
            </div>
        )
    }
}
export default GoodNutrientsGraph