import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { Collapsible, CollapsibleItem, Preloader, Button, Pagination } from 'react-materialize';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import NutrientsModal from './NutrientsModal';
import './css/Recipes.css';

const RECIPE_PER_PAGE_CHOICE = ["5", "10", "15", "20", "25", "50"]

class Recipes extends Component {
  constructor() {
    super();

    this.state = {
      pagesNumber: 10,
      pageSelected: 1,
      isChecked: true
    };
  }

  onSelectPage(value){
    this.setState({
      pageSelected: value
    })
  }

  selectSortOption({value}){
    this.props.sortRecipes(this.state.isChecked, value)
  }

  selectNumRecipesOption({value}){
    this.setState({
      pagesNumber: value,
      pageSelected: 1
    })
  }

  toggleCheckboxChange(){
    this.setState(prevState => ({ isChecked: !prevState.isChecked }), () => {
      this.props.sortRecipes(this.state.isChecked, this.props.sortOption)
      this.forceUpdate()
    })
  }
  
  //function for displaying feedback if macronutrient is in recommended values
  returnPerFeedback(nutrient_type_per, min, max) {
    return <span>{(nutrient_type_per >= min && nutrient_type_per <= max) ? 
    <span className="green-text"><b>OK</b></span> : (nutrient_type_per > max) ? 
    <span className="red-text"><b>HIGH</b></span> : <span className="blue-text"><b>LOW</b></span>}</span>
  }

  renderRecipes() {
    this.props.sortRecipes(this.state.isChecked, this.props.sortOption)
    return _.map(_.chunk(this.props.recipes, this.state.pagesNumber)[this.state.pageSelected-1], (recipe, index) => {
      return(
        <CollapsibleItem 
            key={index} 
            header={recipe.recipe}
            icon='local_dining'> 
          <div className="row">
          	            <div className="col s12" 
                 style={{ 'fontSize': '1em' }}>
              <div className="border-bottom"> {'Global recipe score of NRF, Macronutrient Balance, Reviewers Score'}
                <br/>
                <ul>
                  <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> by Weighted Average Method between [-1 , 1]:  {recipe.cumulative_score_1 ? parseFloat(recipe.cumulative_score_1).toFixed(3) : "-"}</li>
                  <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> by Cogent Confabulation Method between [0 , 1]:  {recipe.cumulative_score_2 ? parseFloat(recipe.cumulative_score_2).toFixed(3) : "-"}</li>
                </ul>
              </div>
              <div> Nutrient Rich Food Score according to RACC (NRF): {recipe.nrf ? parseFloat(recipe.nrf).toFixed(5) : "-"} </div>
              <div className="border-bottom"> Nutrient Rich Food Score according to 100 kcal (NRF_100): {recipe.nrf2 ? parseFloat(recipe.nrf2).toFixed(5) : "-"} </div>
              <div className="col l3 m3 s12 right">
              <br /><NutrientsModal recipe={recipe} />
            </div> 
              <div className="border-bottom"> {'Macronutrient Balance (Carbohydrates: 45-65% total kcal, Fats: 25-35% total kcal, Proteins: 10-35% total kcal)'}
                <br/>
                <ul>
                  <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Protein:  {recipe.protein_per}%  {this.returnPerFeedback(recipe.protein_per, 10, 35)}</li>
                  <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Carbohydrates:  {recipe.carb_per}%  {this.returnPerFeedback(recipe.carb_per, 45, 65)}</li>
                  <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Fat:  {recipe.fat_per}%  {this.returnPerFeedback(recipe.fat_per, 25, 35)}</li>
                </ul>
              </div>
              
              <div className="border-bottom">Reviewers Recipe Score: {recipe.score ? parseFloat(recipe.score) : "-"} </div>
              <div className="border-bottom"> Meal category: {recipe.category ? recipe.category : "-"} </div>
              <div className="border-bottom"> {`Searched ingredients in recipe: ${recipe.ingredients_in_recipe.length}`}
                <br/>
                <ul>
                {_.map(recipe.ingredients_in_recipe, ingr => {
                return <li style={{ 'paddingLeft': '10px' }}key={ingr}> <b>&bull;</b> {ingr}<br/></li>
                })} 
                </ul>
              </div>
              <div className="border-bottom"> Servings per recipe: {recipe.servings ? recipe.servings : "-"} </div>
              <div className="border-bottom"> Calories(kcal) per serving: {recipe.calories ? recipe.calories : "-"} </div>
              <div> Recipe: 
                <a target="_blank" rel="noopener noreferrer" href={recipe.url}> {recipe.url ? recipe.url : "-"} </a>
              </div>
            </div>
            <div className="row">
              <div className="col l12 m12 s12 right">
                <Link to={ `/recipes/${recipe.recipe}` }>
                  <Button className="waves-effect waves-light btn-small right amber accent-1 black-text"
                    style={{ 'marginTop': '20px', textAlign: 'center' }}>
                    Full recipe view
                    <i className="material-icons right">chevron_right</i>
                    </Button>
                </Link>
              </div>
            </div>
          </div>
        </CollapsibleItem>
      );
    });
  }

  renderRecipeDiv(message){
    return(
      <div> 
        <h5>Recipes</h5>
        <p style={{ 'paddingLeft':'10px', 'paddingTop': '10px' }}>
          <i className="material-icons left">info</i> 
          {message} 
        </p>
      </div>
    )
  }

  renderPagination(){
    let pages = this.props.recipes.length/this.state.pagesNumber
    if(this.props.recipes.length%this.state.pagesNumber !== 0)
      pages++
    return(
      
      <div style={{ 'marginTop': '20px' }}>
        <Pagination className="center" 
                    items={pages} 
                    activePage={this.state.pageSelected} 
                    maxButtons={pages} 
                    onSelect={this.onSelectPage.bind(this)}/>
      </div>
    )
  }

  renderSortDropdown(){
    return(
      <div className="right col l6 m6 s12">
        <div style={{ 'marginBottom': '10px' }}>
          Sort Recipes by:
          <Dropdown options={["Score", "NRF RACC - Reference Amount Customarily Consumed", 
                              "NRF 100 Calories", "Calories(kcal)", "Macronutrient Balance", 
                              "Cumulative Score by Weighted Average Method", "Cumulative Score by Cogent Confabulation Method"]} 
                  onChange={this.selectSortOption.bind(this)} 
                  value={this.props.sortOption} 
                  placeholder="Select an option" 
                  //className="myClassName" 
                  controlClassName="myControlClassName" 
                  placeholderClassName="myPlaceholderClassName" 
                  menuClassName="myMenuClassName" 
                  className="noScrollDropdown" />
        </div>
        <div>
          {
            this.state.isChecked ? 
            <p>
              <i className="material-icons left">info</i> 
              {`Recipes are sorted by number of selected ingredients first
              and then by ${this.props.sortOption}`} 
            </p> : 
            ''
          }
        </div>
        <div>
          Number of recipes per page:
          <Dropdown options={RECIPE_PER_PAGE_CHOICE} 
                  onChange={this.selectNumRecipesOption.bind(this)} 
                  value={this.state.pagesNumber.toString()}
                  placeholder="Select an option" 
                  className="noScrollDropdown" /> 
        </div>
      </div>
    )
  }

  render() {
    if(this.props.array.length === 0){
      return this.renderRecipeDiv("To show recipes enter ingredients and search")
    }

    if(this.props.recipes === 'No data'){
      return this.renderRecipeDiv("No recipes in database for wanted ingredients and category")
    }
    
    if(this.props.recipes.length === 0){
      return <div className="center"><Preloader flashing/></div>
    }
    
    return (
      <div style={{ 'padding': '10px 0px' }}>
        <div className="row">
          <div className="left col s12 m6 l6">
            <h5>Recipes</h5>
            <form action="#" style={{ 'marginTop': '30px' }}>
              <p>
                <label>
                  <input type="checkbox" checked={this.state.isChecked} onChange={(e) => this.toggleCheckboxChange()}/>
                  <span>Sort first by number of searched ingredients found in recipe</span>
                </label>
              </p>
            </form>
          </div>
          {this.renderSortDropdown()}
        </div>
        <div>
          <Collapsible popout>
            {this.renderRecipes()}
          </Collapsible>
        </div>
        {(this.props.recipes.length > this.state.pagesNumber) ?  this.renderPagination() : <div style={{ 'marginTop': '20px' }}></div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { recipes: state.recipes['recipes'], sortOption: state.recipes['sort'] };
}
  
export default connect(mapStateToProps, actions)(Recipes);