import { FETCH_INGREDIENTS } from 'actions/types';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_INGREDIENTS:
      const ingredients = action.payload.data
      
      return [...state, ...ingredients];
    default:
      return state;
  }
}