import React, { Component } from 'react';
import RMT from '../images/thumbnail_RMT.png';

class MacronutrientsGraphRMT extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 1280,
            height: 1274,
            carb_per : 0,
            protein_per : 0, 
            fat_per : 0
        };
    }

    componentWillMount() {
        const carb_per = this.props.carb_per;
        const protein_per = this.props.protein_per;
        const fat_per = this.props.fat_per;
        const width = this.props.width;
        const height = this.props.height;

        if(width)
        {
            this.setState({ width : width });
        }

        if(height)
        {
            this.setState({ height : height });
        }
        
        if(carb_per)
        {
            this.setState({ carb_per : carb_per });
        }

        if(protein_per)
        {
            this.setState({ protein_per : protein_per });
        }

        if(fat_per)
        {
            this.setState({ fat_per : fat_per });
        }
    }

    componentDidMount() {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = this.refs.image;
        const lineWidth = canvas.width / 300;
        const circleDiameter = canvas.width / 100;
        const lineColor = "#fa41fe";

        img.onload = () => {

            ctx.drawImage(img, 0, 0, img.width, img.height,         // source rectangle
                            0, 0, canvas.width, canvas.height);     // destination rectangle

            //Fat
            let startX = canvas.width / 10.49;
            let startY = canvas.height - canvas.height / 9.65;
            let endX = canvas.width / 10.49;
            let endY = canvas.height / 31;

            let fullValue = Math.abs(endY - startY);
            let graphAngle = 4.712;
            let lineAngle = 0;

            const fatLine = this.returnMacronutrientLine(startX, startY, graphAngle, lineAngle, fullValue, this.state.fat_per);

            //Protein
            startX = canvas.width / 10.49;
            startY = canvas.height - canvas.height / 9.65;
            endX = canvas.width - canvas.width / 19.5;

            fullValue = Math.abs(endX - startX);
            graphAngle = 0;
            lineAngle = 4.712;

            const proteinLine = this.returnMacronutrientLine(startX, startY, graphAngle, lineAngle, fullValue, this.state.protein_per);

            //Carbohydrates
            startX = canvas.width / 2 + canvas.width / 45.71;
            startY = canvas.height / 2 - canvas.height / 28.95;
            endX = canvas.width / 10.49;
            endY = canvas.height - canvas.height / 9.65;

            graphAngle =  3 * Math.PI / 4;
            lineAngle = 3.926;
            fullValue = Math.sqrt(Math.pow((endX - startX), 2) + Math.pow((endY - startY), 2));

            const carbLine = this.returnMacronutrientLine(startX, startY, graphAngle, lineAngle, fullValue, this.state.carb_per);


            const intersection = this.checkLineIntersection(fatLine.lineStartX, fatLine.lineStartY, fatLine.lineEndX, fatLine.lineEndY,
                                        proteinLine.lineStartX ,proteinLine.lineStartY, proteinLine.lineEndX, proteinLine.lineEndY);

            //if all macronutrient percentages are 0 don't draw anything
            if(parseInt(this.state.fat_per) === 0 && parseInt(this.state.protein_per) === 0 && parseInt(this.state.carb_per) === 0)
            {
                return;
            }
            //if any macronutrient percentage is 100 only draw circle (without dotted lines)
            else if (parseInt(this.state.fat_per) === 100)
            {
                this.drawCircle(ctx, fatLine.fullValueX, fatLine.fullValueY, circleDiameter, lineColor);
            }
            else if (parseInt(this.state.protein_per) === 100)
            {
                this.drawCircle(ctx, proteinLine.fullValueX, proteinLine.fullValueY, circleDiameter, lineColor);
            }
            else if (parseInt(this.state.carb_per) === 100)
            {
                this.drawCircle(ctx, carbLine.fullValueX, carbLine.fullValueY, circleDiameter, lineColor);
            }
            else if (intersection.x && intersection.y)
            {
                //if any macronutrient percentage is 0 don't draw dotted line
                if(parseInt(this.state.fat_per) !== 0)
                {
                    this.drawLine(ctx, fatLine.lineStartX, fatLine.lineStartY, intersection.x, intersection.y, lineWidth, lineColor);
                }
                if(parseInt(this.state.protein_per) !== 0)
                {
                    this.drawLine(ctx, proteinLine.lineStartX, proteinLine.lineStartY, intersection.x, intersection.y, lineWidth, lineColor);
                }
                if(parseInt(this.state.carb_per) !== 0)
                {
                    this.drawLine(ctx, carbLine.lineStartX, carbLine.lineStartY, intersection.x, intersection.y, lineWidth, lineColor);
                }
                //draw circle in intersection of lines 
                this.drawCircle(ctx, intersection.x, intersection.y, circleDiameter, lineColor);
            }
        }
    }

    returnMacronutrientLine(startX, startY, graphAngle, lineAngle, fullValue, macronutrientPercetange) {
        let lineStartX = startX;
        let lineStartY = startY;

        if(macronutrientPercetange <= 0)
        {
            lineStartX = startX;
            lineStartY = startY;
        }
        else if(macronutrientPercetange > 0 && macronutrientPercetange <= 100)
        {
            lineStartX = startX + (macronutrientPercetange / 100 * fullValue * Math.cos(graphAngle));
            lineStartY = startY + (macronutrientPercetange / 100 * fullValue * Math.sin(graphAngle));
        }
        else if(macronutrientPercetange > 100)
        {
            lineStartX = startX + fullValue * Math.cos(graphAngle);
            lineStartY = startY + fullValue * Math.sin(graphAngle);
        }
        
        let lineEndX = lineStartX + fullValue * Math.cos(lineAngle);
        let lineEndY = lineStartY + fullValue * Math.sin(lineAngle);

        let fullValueX = startX + fullValue * Math.cos(graphAngle);
        let fullValueY = startY + fullValue * Math.sin(graphAngle);

        return {lineStartX, lineStartY, lineEndX, lineEndY, fullValueX, fullValueY};
    }

    checkLineIntersection(line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) {
        // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite)
        var denominator, a, b, numerator1, numerator2, result = {
            x: null,
            y: null
        };

        denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
        if (denominator === 0) {
            return result;
        }

        a = line1StartY - line2StartY;
        b = line1StartX - line2StartX;
        numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
        numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
        a = numerator1 / denominator;
        b = numerator2 / denominator;
    
        // if we cast these lines infinitely in both directions, they intersect here:
        result.x = line1StartX + (a * (line1EndX - line1StartX));
        result.y = line1StartY + (a * (line1EndY - line1StartY));
        
        /*
        // it is worth noting that this should be the same as:
        x = line2StartX + (b * (line2EndX - line2StartX));
        y = line2StartX + (b * (line2EndY - line2StartY));
        */

        return result;
    }

    drawLine(ctx, lineStartX, lineStartY, lineEndX, lineEndY, lineWidth, lineColor) {
        let dashLength = lineWidth * 3;

        ctx.setLineDash([dashLength, dashLength]);
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor;
        ctx.moveTo(lineStartX, lineStartY);
        ctx.lineTo(lineEndX, lineEndY);
        ctx.stroke();
    }

    drawCircle(ctx, x, y, circleDiameter, lineColor) {
        ctx.beginPath();
        ctx.fillStyle = lineColor;
        ctx.arc(x, y, circleDiameter, 0, 2 * Math.PI);
        ctx.fill();
    }
   
    render() {
        const width = this.state.width;
        const height = this.state.height;
        return(
            <div>
                <canvas ref="canvas" width={width} height={height} resize="true" style={{'width' : '100%', 'height' : '100%', 'minWidth' : '250px', 'padding' : '1em'}}/>
                <img ref="image" alt="RMT" src={RMT} className="hidden" />
            </div>
        )
    }
}
export default MacronutrientsGraphRMT