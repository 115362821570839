import axios from 'axios';
import { FETCH_INGREDIENTS, FETCH_RECIPES, SORT_RECIPES, EMPTY_RECIPES } from 'actions/types';

export function fetchIngredients() {
  const response = axios.get('http://smart-recipe.skitopisi.com.hr/server/get_ingredients.php');

  return {
    type: FETCH_INGREDIENTS,
    payload: response
  };
}

export function fetchRecipes(ingredients, category, history) {
  const response = axios.get(`http://smart-recipe.skitopisi.com.hr/server/get_recipes.php?ingredients=${ingredients}&category=${category}&number=50&from=0&to=50`);
  //history.push('/recipes')
  
  return {
    type: FETCH_RECIPES,
    payload: response
  };
}

export function sortRecipes(ingredients, option) {
  
  return {
    type: SORT_RECIPES,
    payload: {'sort_by_ingredients_first': ingredients, 'option': option}
  };
}

export function emptyRecipes() {
  
  return {
    type: EMPTY_RECIPES,
    payload: ''
  };
}