import React from 'react';
import _ from 'lodash';
import { Modal, Button } from 'react-materialize';

const renderNutrients = (recipe) => {
  return (
    <div style={{ 'padding': '4px 8px 12px', 
    			  'border': '1px solid #2d2d2d',
    				 }}>
      <div>
        <h3 style={{ 'fontWeight': 'bold',
        			  'borderBottom': '2px solid #000000'}}>Nutrition Facts</h3>
      </div>
      <div>
        <h5>{recipe.recipe}</h5>
        {`Servings per recipe: ${recipe.servings}`}
      </div>
      <div style={{ 'padding': '5px 0px',
      				'borderTop': '15px solid #000000',
      				'borderBottom': '5px solid #000000'}}>
      	<table width="100%">
      		<tr>
      			<td>
       	<span style={{ 'fontWeight': 'bold' }}> {`Amount per serving`} </span>
       	        </td>
       	        <td style={{ 'textAlign': 'right'}} rowspan="2">
        <span style={{ 'fontSize': '50px' }}> {`${recipe.calories}`} </span>
        		</td>
        	</tr>
        	<tr>
        		<td><span style={{ 'fontWeight': 'bold', 'fontSize': '40px' }}> {`Calories`} </span>
        		</td>
        	</tr>
        </table>
      </div>
    
      <div style={{ 'textAlign': 'right',
                    'padding': '4px 0',
                    'borderBottom': '3px solid #2d2d2d'
                  }}>
        <span style={{ 'fontWeight': 'bold', 'fontSize': '15px' }}> % Daily value *</span>
      </div>
   
      <div className="row"
                style={{ 'borderBottom': '1px solid #000000',
                         'marginBottom': '5px' }}
                key={Math.random()}>
              <div className="left col-s6 small"
                  style={{ 'paddingLeft': '0px',
                           'fontWeight': 'bold' }} id="output1">
               
                <span style={{ 'fontWeight': 'normal' }} id="output2"></span>
              </div>
              <div className="right col-s6 small" id="output3">
            
              </div>
            </div>
          
            
      
      <div style={{ 'paddingTop': '10px',
                    'borderBottom': '2px solid #000000'
                  }}>
        {_.map(recipe.nutrients, (nutrient) => {
          let moveLeft = '0px'
          switch (nutrient.nutrient){
            case "Saturated Fat":
            case "Dietary Fiber":
            case "Sugars":
              moveLeft='20px';
              break;
            default:
              moveLeft = '0px';
          }
          return(
            <div className="row"
                style={{ 'borderBottom': '1px solid #000000',
                         'marginBottom': '5px' }}
                key={Math.random()}>
              <div className="left col-s6 small"
                  style={{ 'paddingLeft': moveLeft,
                           'fontWeight': 'bold' }}>
                {`${nutrient.nutrient}:   `}
                <span style={{ 'fontWeight': 'normal' }}>{`${nutrient.value ? nutrient.value : ''}`}</span>
              </div>
              <div className="right col-s6 small">
                {`${nutrient.daily_value ? `${nutrient.daily_value} %` : ''}`}
              </div>
            </div>
          );
        })}
        </div>
        
        <div style={{ 'paddingTop': '10px' }}>
          <div style={{ 'display': 'flex' }}>
            <span style={{ 'flexShrink': '0',
                           'display': 'block',
                           'width': '15px' }}>
                           *
                           </span>
            <span>The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.</span>
          </div>
          <div style={{ 'display': 'flex' }}>
            <span style={{ 'flexShrink': '0',
                           'display': 'block',
                           'width': '15px' }}>
                           **
                           </span>
            <span>Nutrient information is not available for all ingredients. 
              Amount is based on available nutrient data.</span>
          </div>
          <div style={{ 'display': 'flex' }}>
            <span style={{ 'flexShrink': '0',
                           'display': 'block',
                           'width': '15px' }}>
                           -
                           </span>
            <span>Information is not currently available for this nutrient. 
            <br /><br />
              If you are following a medically restrictive diet, 
              please consult your doctor or registered dietitian before preparing this recipe for personal consumption.</span>
          </div>
          <p style={{ 'fontSize': '.875rem',
                      'fontWeight': 'bold',
                      'marginBottom': '4px',
                      'paddingTop': '8px',
                      'paddingLeft': '16px',
                      'paddingRight': '16px',
                      'width': '100%',}}>
            Powered by the ESHA Research Database &copy; 2018, 
            <a href="https://www.esha.com/"> ESHA Research, Inc. </a>

          </p>
        </div>
    </div>
  )
};


const NutrientsModal = ({recipe}) => {
  return (
    <Modal
      trigger={<Button 
                className="waves-effect waves-light btn-small right amber accent-1 black-text"
                style={{ 'marginBottom': '30px', textAlign: 'center' }}>
                  Nutrients
                  <i className="material-icons right">info</i>
                </Button>}>
      {renderNutrients(recipe)}
    </Modal>
  );
};

export default NutrientsModal;