import React, { Component } from 'react';
import badNutrients from '../images/thumbnail_bad_nutrients.png';

class BadNutrientsGraph extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 1280,
            height: 1207,
            saturatedFat : 0,
            totalSugar : 0, 
            sodium : 0
        };
    }

    componentWillMount() {
        const saturatedFat = this.props.saturatedFat;
        const totalSugar = this.props.totalSugar;
        const sodium = this.props.sodium;
        const width = this.props.width;
        const height = this.props.height;

        if(width)
        {
            this.setState({ width : width });
        }

        if(height)
        {
            this.setState({ height : height });
        }
        
        if(saturatedFat)
        {
            this.setState({ saturatedFat : saturatedFat });
        }

        if(totalSugar)
        {
            this.setState({ totalSugar : totalSugar });
        }

        if(sodium)
        {
            this.setState({ sodium : sodium });
        }
    }

    componentDidMount() {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = this.refs.image;
        const lineWidth = canvas.height / 150;
        const circleDiameter = canvas.height / 112.5;

        img.onload = () => {

            ctx.drawImage(img, 0, 0, img.width, img.height,         // source rectangle
                            0, 0, canvas.width, canvas.height);     // destination rectangle

            //Saturated Fat
            let startX = canvas.width / 2 - canvas.width / 136;
            let startY = canvas.height / 2 + canvas.height / 63;
            let angle = 4.7;
            let halfValue = canvas.width / 4.26;
            let fullValue = canvas.width / 2.46;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.saturatedFat, lineWidth, circleDiameter);

            //Total Sugar
            startX = canvas.width / 2 + canvas.width / 161;
            startY = canvas.height / 2 + canvas.height / 26.47;
            halfValue = canvas.width / 4.18;
            fullValue = canvas.width / 2.47;
            angle = 0.605;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.totalSugar, lineWidth, circleDiameter);

            //Sodium
            startX = canvas.width / 2 - canvas.width / 53;
            startY = canvas.height / 2 + canvas.height / 28.125;
            halfValue = canvas.width / 4.077;
            fullValue = canvas.width / 2.44;
            angle = 2.55;

            this.drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, this.state.sodium, lineWidth, circleDiameter);
        }
    }

    drawLineOnGraph(ctx, startX, startY, angle, halfValue, fullValue, nutrientPercetange, lineWidth, circleDiameter) {
        const lineColor = "#16e2e3";

        let valueX = startX;        //X coordinate to draw
        let valueY = startY;        //Y coordinate to draw

        if(nutrientPercetange <= 0)
        {
            valueX = startX;
            valueY = startY;
        }
        else if(nutrientPercetange > 0 && nutrientPercetange <= 50)
        {
            valueX = startX + (nutrientPercetange / 50 * halfValue * Math.cos(angle));
            valueY = startY + (nutrientPercetange / 50 * halfValue * Math.sin(angle));
        }
        else if(nutrientPercetange > 50 && nutrientPercetange <= 100)
        {
            valueX = startX + ((halfValue + (nutrientPercetange - 50) / 50 * (fullValue - halfValue)) * Math.cos(angle));
            valueY = startY + ((halfValue + (nutrientPercetange - 50) / 50 * (fullValue - halfValue)) * Math.sin(angle));
        }
        else if(nutrientPercetange > 100)
        {
            valueX = startX + fullValue * Math.cos(angle);
            valueY = startY + fullValue * Math.sin(angle);
        }
        
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = lineColor;
        ctx.moveTo(startX, startY);
        ctx.lineTo(valueX, valueY);
        ctx.stroke();       // Draw it
        ctx.beginPath();
        ctx.fillStyle = lineColor;
        ctx.arc(valueX, valueY, circleDiameter, 0, 2 * Math.PI);
        ctx.fill();
    }

    render() {
        const width = this.state.width;
        const height = this.state.height;
        return(
            <div>
                <canvas ref="canvas" width={width} height={height} resize="true" style={{'width' : '100%', 'height' : '100%', 'minWidth' : '250px', 'padding' : '1em'}}/>
                <img ref="image" alt="bad_nutrients" src={badNutrients} className="hidden" />
            </div>
        )
    }
}
export default BadNutrientsGraph