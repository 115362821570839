import React from 'react';
import './css/Recipe.css';

const Scroll = () => {
  return(
    <div className="col scroll-menu"
        style={{ 'position':'fixed',
        'right':'3%',
        'top':'10%',
        'width': '150px',
        'textAlign': 'right' }}>
      <ul className="section table-of-contents">
        <li><a href="#g-info">General info</a></li>
        <li><a href="#ingredients">Ingredients</a></li>
        <li><a href="#instructions">Instructions</a></li>
        <li><a href="#nutrients">Nutrient Data</a></li>
      </ul>
    </div>
  );
};

export default Scroll;