import React from 'react';


export default () => {
  return(
    <footer style={{ 'marginTop': '20px' }}
            className="page-footer amber accent-1">
      <div className="container">
        <div className="row">
          <div className="center">
            <h5 className="black-text">Smart Recipe Picker</h5>
            <p className="grey-text text-darken-4">Experimental Web Site for Smart Nutrient-Based Recipe Recommendation developed at <a className="grey-text text-darken-1" href="https://www.fesb.unist.hr/">FESB</a> as a part of research project <b>&rdquo;Application of Intelligent Technologies in Nutrition&rdquo;</b>. <br />Recipes are from <a className="grey-text text-darken-1" href="https://www.allrecipes.com/">Allrecipes.com</a> and ingredients from <a className="grey-text text-darken-1" href="http://foodb.ca/">FoodDB</a> </p>
          </div>
          
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container black-text center">
          &copy; {new Date().getFullYear()} Copyright Smart Recipe Picker - Experimental Web site not intended for commercial use. <br />Privacy policy: We do not collect any information from users of our Web site.<br /><br />Research Team: 
          <br /><span className="grey-text text-darken-1 center" id="Team"> Software development: Ivan Radmilo,Mag.Comp.Sc. and Marin Leventic,Mag.Comp.Sc.; Nutrition: Mirta Stipanicev,Mag.Nutr.Sc.; Supervisor: Prof. Darko Stipanicev,PhD El.Eng. (Contact:dstip@fesb.hr)
        </span>
      </div>
        
      </div>
    </footer>
  
  )
};