import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions';
import _ from 'lodash';
import { Preloader, Collection, CollectionItem, Table } from 'react-materialize';
import './css/Recipe.css';
import Scroll from './Scroll';
import GoodNutrientsGraph from './GoodNutrientsGraph';
import BadNutrientsGraph from './BadNutrientsGraph';
import MacronutrientsGraphRMT from './MacronutrientsGraphRMT';
import MacronutrientsGraphEMT from './MacronutrientsGraphEMT';

class Recipe extends Component {

  constructor() {
    super();

    this.state = {
      selectedIngredient: [],
      category: "All"
    };

    this.onResize = this.onResize.bind(this);
  }

  componentWillMount() {  
    const selectedIngredient = localStorage.getItem('selectedIngredients');
    const category = localStorage.getItem('category');

    if (selectedIngredient) {
      this.setState({ selectedIngredient: JSON.parse(selectedIngredient) });
    }
    
    if (category) {
      this.setState({ category: JSON.parse(category) });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if(this.props.recipe === "No data")
      this.fetchRecipes()

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  fetchRecipes(){
    if (Array.isArray(this.state.selectedIngredient) && this.state.selectedIngredient.length !== 0){
      this.props.fetchRecipes(this.state.selectedIngredient, this.state.category, this.props.history)
    }
    else this.props.history.push('/search')
  }

  onResize()
  {
      if(window.innerWidth <= 768)
      {
      this.setState({isMobile: true});
      }
      else
      {
      this.setState({isMobile: false});
      }
  }

  highlightSearchedIngredientsRegExp(ingredient){
    for(var search of this.props.recipe.ingredients_in_recipe){ 
      var regexstring = '\\b'+search+'(es|s)?\\b'
      var re = new RegExp(regexstring, "gi")
      var array = re.exec(ingredient)
      if(array){
        var index=array['index']
        var lastIndex=index+search.length
        return <span key={ingredient}>
          {ingredient.slice(0, index)}
          <span className="highlight">
          {ingredient.slice(index, lastIndex)}</span>{ingredient.slice(lastIndex)} </span>;
      } 
    }
    return ingredient;
  }

  renderIngredients(){
    return _.map(this.props.recipe.ingredient_list, ingredient => {
      return(
        <div key={ingredient}>
          {this.highlightSearchedIngredientsRegExp(ingredient)}
        </div>
      )
    })
  }

  renderSearchedIngredients(){
    return _.map(this.props.recipe.ingredients_in_recipe, ingredient => {
      return(
        <div key={ingredient}>
          {ingredient}
        </div>
      )
    })
  }

  renderInstructionDivPadding(instruction, padding){
    return(
      <span style={{ 'paddingTop': padding }}>
        {instruction}
      </span>
    )
  }

  renderInstructions(){
    return _.map(this.props.recipe.instructions, (instruction, index) => {
      return(
        <div key={instruction} style={{'marginBottom':'5px'}}>
          <span style={{ 'display': 'flex' }}> 
            <span style={{ 'flexShrink': '0',
                            'display': 'block',
                            'width': '50px' }}>            
              <span className="numberCircle"><span>{index}</span></span>
            </span>
            {index < 10 ? this.renderInstructionDivPadding(instruction, '2px') :
              this.renderInstructionDivPadding(instruction, '6px')
            }
          </span>
        </div>
      )
    })
  }

  renderSpan(prop, data){
    return(
      <span style={{ 'display': 'flex', 'textAlign': 'right' }}> 
        <span style={{ 'flexShrink': '0',
                        'display': 'block',
                        'width': '40px' }}>            
          <span>{prop}</span>
        </span>
        <span>
          {data}
        </span>
      </span>
    );
  }

  renderNutrientsBalance(){
    return(
      <div>
        <div className="graph-container center" style={{ 'display' : 'flex', 'alignItems' : 'center', 'justifyContent' : 'center', 'marginTop' : '20px'}}>
          {this.props.recipe.nutrients.length !== 0 ? 
          <GoodNutrientsGraph protein={this.props.recipe.nutrients[7].daily_value}
                              dietaryFibre={this.props.recipe.nutrients[6].daily_value}
                              vitaminA={this.props.recipe.nutrients[9].daily_value}
                              vitaminC={this.props.recipe.nutrients[10].daily_value}
                              thiamine={this.props.recipe.nutrients[13].daily_value}
                              niacin={this.props.recipe.nutrients[14].daily_value}
                              vitaminB6={this.props.recipe.nutrients[15].daily_value}
                              calcium={this.props.recipe.nutrients[11].daily_value}
                              magnesium={this.props.recipe.nutrients[16].daily_value}
                              iron={this.props.recipe.nutrients[12].daily_value}
                              potassium={this.props.recipe.nutrients[4].daily_value}
                              folate={this.props.recipe.nutrients[17].daily_value}
                              /> : 
          <GoodNutrientsGraph/>}
          {this.props.recipe.nutrients.length !== 0 ? 
          <BadNutrientsGraph  saturatedFat={this.props.recipe.nutrients[1].daily_value}
                              totalSugar={this.props.recipe.nutrients[8].daily_value}
                              sodium={this.props.recipe.nutrients[3].daily_value}
                              /> :
          <BadNutrientsGraph/>}
        </div>
        
      </div>
    )
  }

  renderDetailedNutrientsBalance(){
    return(
      <Table id="detailed-nutr-balance" className="centered" style={{'marginTop' : '30px'}}>
        <tbody>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Protein</b> - RDV 57g
            </td>
            <td>
            {this.props.recipe.nutrients[7] ? <span>{this.props.recipe.nutrients[7].daily_value}% 
            ({this.props.recipe.nutrients[7].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Dietary Fibre</b> - RDV 25g
            </td>
            <td>
            {this.props.recipe.nutrients[6] ? <span>{this.props.recipe.nutrients[6].daily_value}% 
            ({this.props.recipe.nutrients[6].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Vitamin A</b> - RDV 800µg RE (2666IU)
            </td>
            <td>
            {this.props.recipe.nutrients[9] ? <span>{this.props.recipe.nutrients[9].daily_value}% 
            ({this.props.recipe.nutrients[9].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Vitamin C</b> - RDV 80mg
            </td>
            <td>
            {this.props.recipe.nutrients[10] ? <span>{this.props.recipe.nutrients[10].daily_value}% 
            ({this.props.recipe.nutrients[10].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Thiamine - Vitamin B1</b> - RDV 1.1mg
            </td>
            <td>
            {this.props.recipe.nutrients[13] ? <span>{this.props.recipe.nutrients[13].daily_value}% 
            ({this.props.recipe.nutrients[13].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Niacin - Vitamin B3</b> - RDV 16mg
            </td>
            <td>
            {this.props.recipe.nutrients[14] ? <span>{this.props.recipe.nutrients[14].daily_value}% 
            ({this.props.recipe.nutrients[14].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Vitamin B6</b> - RDV 1.4mg
            </td>
            <td>
            {this.props.recipe.nutrients[15] ? <span>{this.props.recipe.nutrients[15].daily_value}% 
            ({this.props.recipe.nutrients[15].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Ca - Calcium</b> - RDV 800mg
            </td>
            <td>
            {this.props.recipe.nutrients[11] ? <span>{this.props.recipe.nutrients[11].daily_value}% 
            ({this.props.recipe.nutrients[11].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Mg - Magnesium</b> - RDV 375mg
            </td>
            <td>
            {this.props.recipe.nutrients[16] ? <span>{this.props.recipe.nutrients[16].daily_value}% 
            ({this.props.recipe.nutrients[16].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Fe - Iron</b> - RDV 14mg
            </td>
            <td>
            {this.props.recipe.nutrients[12] ? <span>{this.props.recipe.nutrients[12].daily_value}% 
            ({this.props.recipe.nutrients[12].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>K - Potassium </b> - RDV 2000mg
            </td>
            <td>
            {this.props.recipe.nutrients[4] ? <span>{this.props.recipe.nutrients[4].daily_value}% 
            ({this.props.recipe.nutrients[4].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="good-nutrient">
            <td className="left nutrient">
              <b>Folate - Vitamin B9</b> - RDV 200mcg(µg)
            </td>
            <td>
            {this.props.recipe.nutrients[17] ? <span>{this.props.recipe.nutrients[17].daily_value}% 
            ({this.props.recipe.nutrients[17].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="bad-nutrient">
            <td className="left nutrient">
              <b>Saturated Fat</b> - MDV 20g
            </td>
            <td>
            {this.props.recipe.nutrients[1] ? <span>{this.props.recipe.nutrients[1].daily_value}% 
            ({this.props.recipe.nutrients[1].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="bad-nutrient">
            <td className="left nutrient">
              <b>Total sugar</b> - MDV 90g
            </td>
            <td>
            {this.props.recipe.nutrients[8] ? <span>{this.props.recipe.nutrients[8].daily_value}% 
            ({this.props.recipe.nutrients[8].value})</span> : <span>-</span>} 
            </td>
          </tr>
          <tr className="bad-nutrient">
            <td className="left nutrient">
              <b>Na - Sodium</b> - MDV 2400mg
            </td>
            <td>
            {this.props.recipe.nutrients[3] ? <span>{this.props.recipe.nutrients[3].daily_value}% 
            ({this.props.recipe.nutrients[3].value})</span> : <span>-</span>} 
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  renderMacronutrientsBalance(){
    return(
      <div className="center">
        <div className="graph-container center" style={{ 'display' : 'flex', 'alignItems' : 'center', 'justifyContent' : 'center', 'marginTop' : '20px'}}>
          {this.props.recipe.total_per ?
          <MacronutrientsGraphRMT carb_per={this.props.recipe.carb_per}
                                  protein_per={this.props.recipe.protein_per}
                                  fat_per={this.props.recipe.fat_per}/> :
          <MacronutrientsGraphRMT/>}

          {this.props.recipe.total_per ?
          <MacronutrientsGraphEMT carb_per={this.props.recipe.carb_per}
                                  protein_per={this.props.recipe.protein_per}
                                  fat_per={this.props.recipe.fat_per}/> :
          <MacronutrientsGraphEMT/>}
        </div>
        <div style={{ 'paddingLeft' : '10px', 'paddingRight' : '1.5rem' }}>
          <blockquote className="flow-text" 
                      style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
            <span style={{'fontWeight':'bold'}}>Macronutrients balance in RMT - 
              Right-angled Mixture Triangle ({this.state.isMobile ? <span>up</span> : <span>left</span>}) and 
              EMT - Equilateral Mixture Triangle ({this.state.isMobile ? <span>down</span> : <span>right</span>})</span>
            <br />
            <span style={{'fontWeight':'bold'}}>Recommended values:</span> Proteins: 10-35% total kcal (Blue Lines),
              Carbohydrates: 45-65% total kcal (Green Lines), Fats: 25-35% total kcal (Red Lines)
            <br />
          </blockquote>
        </div>
        
      </div>
    )
  }

  renderDetailedMacronutrientsBalance(){
    return(
      <Table id="detailed-macro-balance" className="centered" style={{'marginTop' : '30px'}}>
        <tbody>
          <tr className="carbs-row">
            <td className="left macronutrient">
              <b>Carbohydrates (45 - 65 %)</b>
            </td>
            <td className="right">
            <b>{this.props.recipe.carb_per}%  {this.returnPerFeedback(this.props.recipe.carb_per, 45, 65)} - 
            Carbohydrates Score: <span style={{ 'paddingLeft': '0.5em' }}>{this.props.recipe.carb_score}</span></b>
            </td>
          </tr>
          <tr className="protein-row">
            <td className="left macronutrient">
              <b>Protein (10 - 35 %)</b>
            </td>
            <td className="right">
              <b>{this.props.recipe.protein_per}%  {this.returnPerFeedback(this.props.recipe.protein_per, 10, 35)} - 
              Protein Score: <span style={{ 'paddingLeft': '0.5em' }}>{this.props.recipe.protein_score}</span></b>
            </td>
          </tr>
          <tr className="fat-row">
            <td className="left macronutrient">
              <b>Fat (25 - 35 %)</b>
            </td>
            <td className="right">
              <b>{this.props.recipe.fat_per}%  {this.returnPerFeedback(this.props.recipe.fat_per, 25, 35)} - 
              Fat Score: <span style={{ 'paddingLeft': '0.5em' }}>{this.props.recipe.fat_score}</span></b>
            </td>
          </tr>
          <tr className="macro-total-score-row">
            <td className="left macronutrient">
              <b>Macronutrients Total Score</b>
            </td>
            <td className="right">
              <b>{this.props.recipe.macronutrient_score}</b>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  renderNutrientInfoCollectionItems(){
    return(
      <div style={{'marginTop': '30px'}}>
        <Collection className="row">
          <CollectionItem key={this.props.recipe.recipe + "_nrf"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Nutrient Rich Food Score according to RACC</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.nrf).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
          <CollectionItem key={this.props.recipe.recipe + "_nr"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Nutrient Rich Score according to RACC</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.nr).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
          <CollectionItem key={this.props.recipe.recipe + "_lim3"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px'}}>
                <b>Limit 3 Nutrients Score according to RACC</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.lim3).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
        </Collection>
      </div>
    )
  }

  renderNutrientInfoCollectionItems2(){
    return(
      <div style={{'marginTop': '30px'}}>
        <Collection className="row">
          <CollectionItem key={this.props.recipe.recipe + "_nrf2"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Nutrient Rich Food Score according to 100 kcal</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.nrf2).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
          <CollectionItem key={this.props.recipe.recipe + "_nr2"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Nutrient Rich Score according to 100 kcal</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.nr2).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
          <CollectionItem key={this.props.recipe.recipe + "_lim32"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px'}}>
                <b>Limit 3 Nutrients Score according to 100 kcal</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.lim32).toFixed(5)}    
              </div>
            </div>
          </CollectionItem>
        </Collection>
      </div>
    )
  }

  renderReviewersScoreCollectionItems(){
    return(
      <div style={{'marginTop': '30px'}}>
        <Collection className="row">
          <CollectionItem key={this.props.recipe.recipe + "_score"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Score</b>
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.score).toFixed(4)}    
              </div>
            </div>
          </CollectionItem>
        </Collection>
      </div>
    )
  }

  renderCumulativeScoresCollectionItems(){
    return(
      <div style={{'marginTop': '30px'}}>
        <Collection className="row">
          <CollectionItem key={this.props.recipe.recipe + "_cumulative_score_1"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Cumulative Nutrient Score by Weighted Average Method</b> in interval [-1 , 1]
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.cumulative_score_1).toFixed(4)}    
              </div>
            </div>
          </CollectionItem>
          <CollectionItem key={this.props.recipe.recipe + "_cumulative_score_2"} style={{ 'padding': '0px 20px' }}>
            <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
              <div className="left col s9" style={{ 'marginTop': '5px' }}>
                <b>Cumulative Nutrient Score by Cogent Confabulation Method</b> in interval [0 , 1]
              </div>
              <div className="right col s3" style={{ 'marginTop': '5px', 'textAlign': 'right' }}> 
                {parseFloat(this.props.recipe.cumulative_score_2).toFixed(4)}    
              </div>
            </div>
          </CollectionItem>
        </Collection>
      </div>
    )
  }

  renderRecipeTimeInfo(prop){
    let info = ''
    let time = ''
    switch(prop){
      case "prep":
        info="Prep"
        time=this.props.recipe.prep_time
        break;
      case "cook":
        info="Cook"
        time=this.props.recipe.cook_time
        break;
      case "ready":
        info="Ready"
        time=this.props.recipe.ready_time
        break;
      default:
        return;
    }
    return(
      <span style={{ 'display': 'flex' }}> 
        <span style={{ 'flexShrink': '0',
                        'display': 'block',
                        'width': '50px' }}>            
          <span>{info}</span>
        </span>
        <span>
          {time}
        </span>
      </span>
    )
  }

  //function for displaying feedback if macronutrient is in recommended values
  returnPerFeedback(nutrient_type_per, min, max) {
    return <span>{(nutrient_type_per >= min && nutrient_type_per <= max) ? 
    <span className="green-text"><b>OK</b></span> : (nutrient_type_per > max) ? 
    <span className="red-text"><b>HIGH</b></span> : <span className="blue-text"><b>LOW</b></span>}</span>
  }

  renderRecipeInfo(){
    return(
      <div>
        <h4 className="center" style={{ 'paddingTop':'30px' }}> 
          {this.props.recipe.recipe}
        </h4>
        <div id="g-info" className="recipe_look section scrollspy"> 
          General info
        </div>
        <div className="row" style={{ 'textAlign' : 'justify' }}>
          <div>
            <div>
              <ul>{'Global recipe score of NRF, Macronutrient Balance, Reviewers Score'}
                <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> by Weighted Average Method between [-1 , 1]:  {this.props.recipe.cumulative_score_1 ? parseFloat(this.props.recipe.cumulative_score_1).toFixed(3) : "-"}</li>
                <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> by Cogent Confabulation Method between [0 , 1]:  {this.props.recipe.cumulative_score_2 ? parseFloat(this.props.recipe.cumulative_score_2).toFixed(3) : "-"}</li>
              </ul>
            </div>
            <div>Nutrient Rich Food Score per serving (NRF): {this.props.recipe.nrf ? parseFloat(this.props.recipe.nrf).toFixed(5) : "-"} </div>
            <div>Nutrient Rich Food Score per 100 kcal (NRF_100): {this.props.recipe.nrf2 ? parseFloat(this.props.recipe.nrf2).toFixed(5) : "-"} </div>
            <div>
              <ul>{'Macronutrient Balance (Carbohydrates: 45-65% total kcal, Fats: 25-35% total kcal, Proteins: 10-35% total kcal)'}
                <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Protein:  {this.props.recipe.protein_per}%  {this.returnPerFeedback(this.props.recipe.protein_per, 10, 35)}</li>
                <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Carbohydrates:  {this.props.recipe.carb_per}%  {this.returnPerFeedback(this.props.recipe.carb_per, 45, 65)}</li>
                <li style={{ 'paddingLeft': '10px' }}> <b>&bull;</b> Fat:  {this.props.recipe.fat_per}%  {this.returnPerFeedback(this.props.recipe.fat_per, 25, 35)}</li>
              </ul>
            </div>
            <div>Reviewers Recipe Score: {this.props.recipe.score ? parseFloat(this.props.recipe.score).toFixed(5) : "-"} </div><br />
            <div>Meal category: {this.props.recipe.category ? this.props.recipe.category : "-"} </div>
            <div>
              <ul>{`Searched ingredients in recipe: ${this.props.recipe.ingredients_in_recipe.length}`}
              {_.map(this.props.recipe.ingredients_in_recipe, ingr => {
              return <li  style={{ 'paddingLeft': '10px' }} key={ingr}> <b>&bull;</b> {ingr}<br/></li>
              })} 
              </ul>
            </div>
            <div>Servings per recipe: {this.props.recipe.servings ? this.props.recipe.servings : "-"} </div>
            <div>Calories(kcal) per serving: {this.props.recipe.calories ? this.props.recipe.calories : "-"} </div>
            <div>Recipe: 
              <a target="_blank" rel="noopener noreferrer" href={this.props.recipe.url}> {this.props.recipe.url ? this.props.recipe.url : "-"} </a>
            </div>
          </div>
        </div>
        <div id="s-ingredients" className="recipe_look section scrollspy">
          Searched ingredients
        </div>
        <div>
          {this.renderSearchedIngredients()}
        </div>
        <div id="ingredients" className="recipe_look section scrollspy">
          Ingredients
        </div>
        <div style={{ 'textAlign' : 'justify' }}>
          {this.renderIngredients()}
        </div>
        <div id="instructions" className="recipe_look section scrollspy">
          Instructions
        </div>
        <div className="row" style={{ 'marginTop': '10px' }}>
          <div className="left col s6" style={{'marginBottom':'10px'}}>
            <i className="material-icons">timer</i>
              {this.props.recipe.prep_time ? this.renderRecipeTimeInfo("prep") : ''}
              {this.props.recipe.cook_time ? this.renderRecipeTimeInfo("cook") : ''}
              {this.props.recipe.ready_time ? this.renderRecipeTimeInfo("ready") : ''}
          </div>
          <div className="col m12 l12 s12" style={{ 'marginTop': '20px' , 'textAlign' : 'justify'}}>
            {this.renderInstructions()}
          </div>
        </div>
        <div className="row">   
          <div className="left col l12 m12 s12" style={{ 'paddingBottom': '20px' }}>
            <div id="nutrients" className="recipe_look center section scrollspy">
              Nutrient data
            </div>
            {this.props.recipe.nutrients.length !== 0 ?
              <div className="center" style={{'marginTop' : '30px'}}>
                {/* {this.props.recipe.nutrients.length !== 0} */}
                {`Per Serving: ${this.props.recipe.calories} Calories(kcal);
                ${this.props.recipe.nutrients[0].value} fat; 
                ${this.props.recipe.nutrients[5].value} carbohydrates; 
                ${this.props.recipe.nutrients[7].value} protein; 
                ${this.props.recipe.nutrients[2].value} cholesterol; 
                ${this.props.recipe.nutrients[3].value} sodium`}
              </div> :
              <div className="center red-text" style={{'fontWeight' : 'bold', 'marginTop' : '30px'}}>
                No nutritional data for this recipe
              </div>
            }
        
              <div className="center" style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>NUTRIENTS BALANCE</b></h6>
              {this.renderNutrientsBalance()}
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              {this.renderDetailedNutrientsBalance()}
              <div style={{ 'marginTop' : '20px', 'paddingLeft' : '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Nutrients balance of 12 recommended nutrients
                  and 3 nutrients whose intake should be limited.</span>
                  <span style={{'fontWeight':'bold'}}> RDV - Recommended Daily Values</span> and
                  <span style={{'fontWeight':'bold'}}> MDV - Maximum Daily Values</span> are according to EFSA - 
                  European Food and Safety Authority based on an intake of 2000 kcal/day (8370 kJ/day). Nutrients values of the recipe are  
                   <span style={{'fontWeight':'bold'}}> per serving</span>, so a percetage say how much one serving of this dish meets the daily requirements of 
                  recommanded nutrients (RDV) and limited nutrients (MDV).
                  <br />
                </blockquote>
              </div>
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>Nutrient Rich Food Score Index accoding to RACC</b></h6>
              {this.renderNutrientInfoCollectionItems()}
              <div style={{ 'paddingLeft': '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Nutrient Rich Food Score (NRF)</span> is 
                  calculated as <b>NRF = NR &minus; LIM3</b> where:
                  <br/>
                  <span style={{'fontWeight':'bold'}}>Nutrient Rich Score (NR)</span> is calculated by summation <b>NR = SUM(nutrient_i/RDV_i)</b> where nutrient_i is one of 
                  12 recommended nutrients (Protein, Fibre, Calcium, Potassium,
                  Magnesium, Iron, Vitamin A, Vitamin C, Thiamine, Niacin, Vitamin B6, Folate),
                  <br />
                  <span style={{'fontWeight':'bold'}}>Limit 3 Score (LIM3)</span> calculated by summation <b>LIM3 = SUM(nutrient_j/MDV_j)</b> where nutrient_j is one of 
                   3 nutriets whose intake should be limited (Saturated fats, Sodium, Sugars).
                  <br />
                  Theoretical maximal value of NRF is 12 (100% of Recommended Daily Values (RDV) of recommended
                  nutrients and 0% of limited nutrients (MDV)).
                  <br />
                  <span style={{'fontWeight':'bold'}}><i>Note:</i></span> All nutrient values are according to RACC
                  - Reference Values Customarily Consumed (weight of nutrient per serving)) based on article 
                  <a target="_blank" rel="noopener noreferrer" href="https://academic.oup.com/ajcn/article/91/4/1095S/4597206"> <u>Adam Drewnowski, 
                  <i> The Nutrient Rich Foods Index helps to identify healthy, affordable foods,
                  The American Journal of Clinical Nutrition, Volume 91, Issue 4, April 2010, Pages 1095S–1101S.</i></u></a> The only difference is 
                  that we have used 12 recommended nutrients instead of 9.
                  <br />
                </blockquote>
              </div>
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>Nutrient Rich Food Score Index according to 100 kcal</b></h6>
              {this.renderNutrientInfoCollectionItems2()}
              <div style={{ 'paddingLeft': '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Nutrient Rich Food Score according to 100 kcal (NRF_100)</span> is 
                  calculated as <b>NRF_100 = NR_100 &minus; LIM3_100</b> where:
                  <br/>
                  <span style={{'fontWeight':'bold'}}>Nutrient Rich Score (NR_100)</span> is calculated from NR dividing by Calories(kcal) <b>NR_100 = NR/calories_i</b> where calories_i 
                  are per serving,
                  <br />
                  <span style={{'fontWeight':'bold'}}>Limit 3 Score (LIM3_100)</span> is calculated from LIM3 dividing by Calories(kcal) <b>LIM3_100 = LIM3/calories_i</b> where calories_i 
                  are per serving.
                  <br />
            
                  <span style={{'fontWeight':'bold'}}><i>Note:</i></span> Formulas are according to article 
                  <a target="_blank" rel="noopener noreferrer" href="https://academic.oup.com/ajcn/article/91/4/1095S/4597206"> <u>Adam Drewnowski, 
                  <i> The Nutrient Rich Foods Index helps to identify healthy, affordable foods,
                  The American Journal of Clinical Nutrition, Volume 91, Issue 4, April 2010, Pages 1095S–1101S.</i></u></a> The only difference is 
                  that we have used 12 recommended nutrients instead of 9.
                  <br />
                </blockquote>
              </div>
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>MACRONUTRIENTS BALANCE</b></h6>
              {this.renderMacronutrientsBalance()}
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              {this.renderDetailedMacronutrientsBalance()}
              <div style={{ 'marginTop' : '20px', 'paddingLeft' : '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Macronutrient balance: </span>Proteins: 10-35% total kcal, 
                  Carbohydrates: 45-65% total kcal, Fats: 25-35% total kcal – Green means OK, 
                  red high and blue low. If the macronutrient is inside recommended interval its score is 1, 
                  if it is bellow its score is in interval [0 , 1] and if it is higher its score is in interval [-1 , 0]
                  <br />
                  <span style={{'fontWeight':'bold'}}>Macronutrient Total score: </span>It is average value of all 
                  three scores (Protein Score + Carbohydrates Score + Fat Score)/3, so it takes values in interval [-1 , 1]. 
                  <br />
                </blockquote>
              </div>
            </div>
               
            <div style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>Reviewers Score</b></h6>
              {this.renderReviewersScoreCollectionItems()}
              <div style={{ 'marginTop' : '20px', 'paddingLeft': '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Score </span>is score given by reviewers, normalised score in interval 
                  [0,1] and weighted by the number of reviewers (it increases with the number of reviewers).
                  <br />
                </blockquote>
              </div>
            </div>
            <div style={{ 'marginTop' : '50px'}}>
              <h6 className="center"><b>Cumulative Scores</b></h6>
              {this.renderCumulativeScoresCollectionItems()}
              <div style={{ 'marginTop' : '20px', 'paddingLeft': '10px', 'paddingRight' : '1.5rem' }}>
                <blockquote className="flow-text" 
                            style={{ 'fontSize': '0.9em', 'marginBottom': '40px', 'textAlign' : 'justify' }}>
                  <span style={{'fontWeight':'bold'}}>Cumulative Nutrient Score by Superposition </span>is in interval [-1 , 1] is 
                  calculated using superposition formula Cumulative_Score = ((NRF/Max(NRF)) + Macronutrient_Total_Score + Reviewers_Score)/3
                  <br />
                  <span style={{'fontWeight':'bold'}}>Cumulative Nutrient Score by Cogent Confabulation </span>is in interval 
                  [0 , 1] calculated accoding to "winer-takes-it-all" principle from NRF_100, macronutrient scores of protein, 
                  carbohydrates, fat and reviewers score using formula <b>CC_Cumulative_Score = (NRF_100/Max(NRF_100) * Protein_Score * Carbohydrates_Score * Fat_Score * Reviewers_Score</b>. 
                  <br />
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    )  
  }

  renderPreloading(){
    return(
      <div className="center">
        <Preloader flashing/>
      </div>
    )
  }

  redirectToSearch(){
    this.props.history.push(`/search`)
  }

  render(){
    if(this.props.recipes.length !== 0 && this.props.recipe === "No data")
      return(
        <div className="row" style={{ 'margin': '40px 0px' }}>
          <div className="center">
            No recipe fetched with specific name provided as a part of url 
            <br />
            Redirecting you to search
            <span style={{ 'display': 'None'}}> {setTimeout(() => this.redirectToSearch(), 1500)} </span>
          </div>
        </div>
      )
    return(
      <div className="row" style={{ 'margin': '20px 0px' }}>
        <Scroll />
        {this.props.recipe !== "No data" ? this.renderRecipeInfo() : this.renderPreloading()}
      </div>
    )
  }
}

function mapStateToProps({ recipes }, ownProps) {
  let searchedRecipe = _.find(recipes['recipes'], (recipe) => {
    return recipe.recipe === ownProps.match.params.name;
  })
  if(searchedRecipe !== undefined)
    return { recipes: recipes['recipes'], recipe: searchedRecipe };
  else
    return { recipes: recipes['recipes'], recipe: "No data" }
}
    
export default connect(mapStateToProps, actions)(Recipe);