import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import SearchBox from 'components/SearchBox';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Home from 'components/Home';
import Recipe from 'components/Recipe';
import './css/App.css';
import ScrollToTop from 'react-scroll-up';

class App extends Component {

  render() {
    return (
      <div className="container">
        <BrowserRouter>
          <div className="app">
            <Header />
            <ScrollToTop showUnder={160}>
              <span className="material-icons amber accent-1 numberCircle"><span>keyboard_arrow_up</span></span>
            </ScrollToTop>
            <main>
              <Route exact path="/" component={Home} />
              <Route exact path="/search" component={SearchBox} />
              <Route exact path="/recipes/:name" component={Recipe} />
              <Footer />
            </main>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
