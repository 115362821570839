import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './css/Header.css'

class Header extends Component {

  componentDidMount() {
    this.unlisten = this.props.history.listen(location => {
      this.setState({ location });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render(){
    return(
    <nav>
      <div className="nav-wrapper amber accent-1">
        <a className="brand-logo center black-text" href="/">Smart Recipe Picker</a>
        <ul id="nav-mobile" className="left hide-on-med-and-down">
            <li><Link className={`black-text ${this.props.history.location.pathname === "/" ? 'highlight-link' : ''}`} to="/">Home</Link></li>
            <li><Link className={`black-text ${this.props.history.location.pathname === "/search" ? 'highlight-link' : ''}`} to="/search">Search</Link></li>
        </ul>
      </div>
    </nav>  
    )
  }
};

export default connect()(withRouter(Header));