import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'actions';
import Search from 'react-search-box';
import './css/SearchBox.css';
import { Preloader, Collection, CollectionItem } from 'react-materialize';
import Recipes from './Recipes';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

const CATEGORIES = [
  "All",
  "Appetizers and Snacks",
  "Bread",
  "Breakfast and Brunch",
  "Desserts",
  "Drinks",
  "Main Dish",
  "Side Dish",
  "Salads",
  "Soups, Stews and Chili"
]

class SearchBox extends Component {

  constructor() {
    super();

    this.state = {
      array: [],
      category: "All",
      searching: false
    };
  }

  componentWillMount() {
    const cachedData = localStorage.getItem('selectedIngredients');
    const category = localStorage.getItem('category');

    if (cachedData) {
      this.setState({ array: JSON.parse(cachedData) });
    }
    
    if (category) {
      this.setState({ category: JSON.parse(category) });
    }

    if (!Array.isArray(this.props.ingredients) || !this.props.ingredients.length) {
      this.props.fetchIngredients()
    }
  }

  componentDidMount() {
    this.props.sortRecipes(true, "Score")
    if(this.props.recipes.length === 0)
      this.fetchRecipes()
  }

  turnFirstToUpper(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  selectCategory(category){
    localStorage.setItem('category', JSON.stringify(category.value));
    if(this.state.category !== category.value) 
      this.setState({ category: category.value }, () => {
        this.props.emptyRecipes()
        this.fetchRecipes()
      })
  }

  handleChange(value) {
    if(!this.state.array.includes(value)) {
      this.state.array.push(value);
      localStorage.setItem('selectedIngredients', JSON.stringify(this.state.array));  
      this.setState(prevState => ({ array: prevState.array }))
    }
  }

  removeFromArray(ingredient) {
    this.setState(prevState => ({
      array: prevState.array.filter(el => el !== ingredient)
    }), () => {
      localStorage.setItem('selectedIngredients', JSON.stringify(this.state.array));
    });
  }

  removeAllIngredientsFromArray(){
    this.setState({ array: [] }, () => {
      localStorage.setItem('selectedIngredients', JSON.stringify(this.state.array));
    });
  }

  renderIngredients() {
    return this.state.array.map(ingredient => {
      return(
        <CollectionItem key={ingredient} style={{ 'padding': '0px 20px' }}>
          <div style={{ 'paddingLeft': '10px', 'paddingTop': '15px' }} className="row">
            <div className="left col s6" style={{ 'marginTop': '5px' }}>
              {ingredient} 
            </div>
            <div className="left col s6">
              <button onClick={ this.removeFromArray.bind(this, ingredient) } 
                      className="btn-floating btn-small right red lighten-2 waves-effect waves-light"
                      style={{ 'marginLeft': '30px', textAlign: 'center' }}>
                      <i className="material-icons center">delete</i>
              </button>
            </div>
          </div>
        </CollectionItem>
      )
    });
  }

  renderHelpMessage(){
    return(
      <div style={{ 'paddingLeft': '10px' }}>
        <p>
          <i className="material-icons left">info</i> 
          You have to select some ingredients.
        </p>
      </div>
    )
  }

  fetchRecipes() {
    if (Array.isArray(this.state.array) && this.state.array.length !== 0){
      this.props.emptyRecipes()
      this.props.fetchRecipes(this.state.array, this.state.category, this.props.history)
    }
  }

  render() {
    if(this.props.ingredients.length === 0){
      return(
        <div style={{ 'marginTop': '20px' }}> Loading ingredients <Preloader flashing/></div>
      )
    }

    return (
      <div style={{ 'marginTop': '40px' }}>
        <h6>Search ingredients</h6>
        
        <Search
          data={ this.props.ingredients }
          onChange={ this.handleChange.bind(this) }
          placeholder="Search for an ingredient..."
          class="search-class"
          searchKey="value"
        />

        <div className="row valign-wrapper">
          <div className="left col s6 l6 m6">
            <h5 style={{ 'padding': '20px 0px' }}> Selected ingredients </h5>
          </div>
          <div className="right right-align col s6 l6 m6"
               style={{ 'marginRight': '23px' }}>
            <div className="right">
              <button onClick={ this.removeAllIngredientsFromArray.bind(this) } 
                      className="btn-floating btn-small right red lighten-2 waves-effect waves-light">
                      <i className="material-icons center">delete_sweep</i>
              </button>
            </div>
            <div className="right hide-on-small-only" style={{ 'padding': '5px 5px' }}>
              Clear all ingredients
            </div>   
          </div>
        </div>
        <Collection>
          { this.state.array.length ?  this.renderIngredients() : this.renderHelpMessage() }
        </Collection>
        <div className="row" style={{ 'paddingTop': '10px' }}>
          <div className="left col l6 m6 s12">
            <button onClick={ this.fetchRecipes.bind(this) } 
                    disabled={ !this.state.array.length }
                    className="waves-effect waves-light btn-small center amber accent-1 black-text"
                    style={{ textAlign: 'center' }}>
                    Search recipes
              <i className="material-icons right black-text">search</i>
            </button>
          </div>
          <div className="right col l5 m5 s12" style={{ }}>
            <div className="left" style={{ 'marginTop': '7px', 'marginRight': '10px' }}>
              Categories:
            </div>
            <div className="center">
              <Dropdown options={CATEGORIES} 
                        onChange={this.selectCategory.bind(this)} 
                        value={this.state.category} 
                        placeholder="Select an option" 
                        className="noScrollDropdownBig" />
            </div>
          </div>
        </div>
        <div>
          <Recipes array={this.state.array}
                   category={this.state.category}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ingredients: state.ingredients, recipes: state.recipes['recipes'] };
}

export default connect(mapStateToProps, actions)(SearchBox);