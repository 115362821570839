import React from 'react';
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div style={{ textAlign: 'center', 'marginBottom': '40px' }}>
      <h1>
        Smart Recipe Picker
      </h1>
      <p>  Smart Nutrient-Based Recipe Recommendation Web App.  </p>
      <button className="btn btn-secondary">
      <Link to={`/search`}>
        To search for recipes click here
      </Link>
      </button>
    </div>
  );
};

export default Home;
